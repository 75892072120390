
export default {
	
	options: {
		
		state: [
			{ id: 1, label: '待结算'},
			{ id: 2, label: '已结算'},
			{ id: 3, label: '已取消'}
		],
		settlementType: [
			{ id: 1, label: '线下结算'},
			{ id: 2, label: '线上结算'},
			
		],
		classId: [
			{ id: 1, label: '商城服务商结算'},
			
		],
		serviceType:[
			{ id: 1, label: '商城账单'},
			{ id: 2, label: '旅游账单'},
			{ id: 3, label: '加油账单'}
		],
		sMchState:[
			{ id: 0, label: '待生效'},
			{ id: 1, label: '待结算'},
			{ id: 2, label: '结算中'},
			{ id: 3, label: '已结算'},
			{ id: 4, label: '已取消'},
			{ id: 5, label: '已撤销'},
		],
		pMchState:[
			{ id: 0, label: '待生效'},
			{ id: 1, label: '待结算'},
			{ id: 2, label: '结算中'},
			{ id: 3, label: '已结算'},
			{ id: 4, label: '已取消'},
			{ id: 5, label: '已撤销'},
		]
	}
}